import React from 'react'
import { graphql } from 'gatsby'
import { Trans } from 'gatsby-plugin-react-i18next'

import Layout from '../components/layout'
import SEO from '../components/seo'
import Button from '../components/button'
import '../styles/pages/contact.scss'

const MainSection = ({ data, info }) => {
  function cleanPhone(phone) {
    return phone.replace(/[\W_]+/g, '')
  }
  return (
    <section className="text-tuya-rho main-section px-6 py-8 pt-36 md:p-20 md:pr-1 md:pt-48 md:pb-0 lg:p-32 lg:pb-0 md:-mb-36 lg:mb-0 2xl:max-w-screen-xl 2xl:mx-auto 2xl:px-0">
      <div className="content-wrapper w-[460px] xl:w-[689px]">
        <h1 className="text-4xl py-10 2xl:text-6xl">
          <Trans>contact_us</Trans>
        </h1>
        <p className="font-light mb-10 text-tuya-rho xl:mb-14 xl:leading-7.5 4xl:text-[28px] 4xl:leading-11">
          {data.mainText}
        </p>
        <div>
          <h6 className="mb-4 text-tuya-slate xl:leading-6 xl:mb-6 font-light 4xl:w-[460px]">
            <Trans>for_reservation</Trans>:
          </h6>
          <p className="text-xl mb-16 leading-7.5 font-light">
            <Trans>contact_phone</Trans>: <a href={`tel:+${cleanPhone(info.phone)}`}>{info.phone}</a>
            <br />
            <Trans>contact_email</Trans>: <a href={`mailto:${info.email}`}>{info.email}</a>
            <br />
            {info.address}
          </p>
        </div>
        <div className="mb-16 3xl:hidden">
          <a href="/reservation">
            <Button isDark>
              <Trans>reservation</Trans>
            </Button>
          </a>
        </div>
      </div>
      <div className="img-wrapper mb-10 md:mb-0 lg:pt-10 3xl:w-[712px]">
        <div className="opening-hours mb-4 hidden lg:block">
          <h6 className="text-tuya-iota mb-1 small-text-spaced">
            <Trans>opening_hours</Trans>
          </h6>
          <p>
            {info.scheduleDays}: {info.scheduleHours}
          </p>
        </div>
        <img
          className="object-cover w-full h-full"
          src={data.image.sizes.src}
          srcSet={data.image.sizes.srcSet}
          alt="Tuya Resturant"
        />
      </div>
      <div className="hidden 3xl:block row-start-2 col-start-1">
        <div className="h-full w-full reservation flex items-end">
          <a href="/reservation">
            <Button isDark>
              <Trans>reservation</Trans>
            </Button>
          </a>
        </div>
      </div>
    </section>
  )
}

const ContactPage = ({ data: graphqlData }) => {
  const data = graphqlData.datoCmsContact
  const info = graphqlData.datoCmsResturantInformation
  return (
    <Layout className="contact-page" alwaysBlack>
      <SEO title="Contact" />
      <MainSection data={data} info={info} />
    </Layout>
  )
}

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    datoCmsContact(locale: { eq: $language }) {
      image {
        sizes {
          src
          srcSet
        }
      }
      mainText
    }
    datoCmsResturantInformation {
      address
      email
      facebookLink
      instagramLink
      phone
      pinterestLink
      scheduleDays
      scheduleDaysSecond
      scheduleHours
      scheduleHoursSecond
      locale
      linkedinLink
    }
  }
`

export default ContactPage
